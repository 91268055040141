import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseJson } from '../models/model';

const httpOptions = {
  headers: new HttpHeaders({ 'Accept': 'application/json' })
};


@Injectable({ providedIn: 'root' })
export class BaseService {
  constructor(protected http: HttpClient) { }

  public get<T>(url: string, requestBody?: any): Observable<ResponseJson<T>> {// eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.get<T>(url, { headers: httpOptions.headers, params: requestBody }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public post<T>(url: string, requestBody?: any | null, responseType?: any): Observable<ResponseJson<T>>{ // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.post<T>(url, requestBody, { headers: httpOptions.headers, responseType }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public put<T>(url: string, requestBody: any | null): Observable<ResponseJson<T>> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.put<T>(url, requestBody, { headers: httpOptions.headers }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public delete<T>(url: string, requestBody?: any | null): Observable<ResponseJson<T>> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.delete<T>(url, { headers: httpOptions.headers, body: requestBody }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  protected handleError(error: HttpErrorResponse): Observable<any> { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(JSON.stringify(error));
      console.error(`Backend returned code ${error.status}, body was: ${error.error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error.error);
  }

}
